/**
 * Created by Jeongbin on 2017-05-17.
 */
import { createStore, applyMiddleware } from 'redux';
import reducers from '../reducers';
import thunk from 'redux-thunk';

export const store = createStore(
  reducers,
  applyMiddleware(thunk)
);
