/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_PAYMENT } from "../actions/ActionTypes";

const initialState = {
  payment: {
    selected: 2,
    id: "year1",
    options: {
      // month1: {
      //   value: 1,
      //   period:'월정기 결제(카드결제만 가능)',
      //   checked: false,
      //   show:''
      // },
      year1: {
        value: 2,
        period: "1년",
        checked: true,
        show: ""
      },
      year2: {
        value: 3,
        period: "2년",
        checked: false,
        show: ""
      }
    }
  }
};

// console.log("initialState.payment:"+  Object.keys(initialState));

const applyPaymentReducer = (payment = initialState.payment, action) => {
  let { id, value } = action.params || { id: "", value: "" };

  switch (action.payment) {
    case APPLY_PAYMENT.CHANGE:
      Object.keys(payment.options).forEach(key => {
        payment.options[key].checked = false;
        if (key === id) {
          payment.options[key].checked = true;
          payment.selected = value;
          payment.id = key;
        }
      });

      // console.log("payment.options: " + payment.options);

      return { ...payment };

    default:
      return payment;
  }
};

export default applyPaymentReducer;
