/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_COMPANY } from "../actions/ActionTypes";

const initialState = {
  company: {
    selected: "",
    product: "",
    price: "",
    type: "",
    label: "",
    promotion: "",
    options: {},
  },
};

const applyCompanyReducer = (company = initialState.company, action) => {
  //  let { id, value } = action.params || { id: "", value: "" };
  // let { option } = action.params;

  // console.log("#### Company reducer");
  // console.log(action.params);
  // console.log(option);
  //   console.log(value);

  switch (action.company) {
    case APPLY_COMPANY.CHANGE:
      // Object.keys(company.options).map((key) => {
      //   company.options[key].checked = false;

      //   if (key === id) {
      //     company.options[key].checked = true;
      //     company.selected = value;
      //   }
      // });

      // console.clear();
      // console.log(action.params);

      if (action.params.label) company.label = action.params.label;
      if (action.params.price) company.price = action.params.price;
      if (action.params.selected) company.selected = action.params.selected;
      if (action.params.product) company.product = action.params.product;
      if (action.params.type) company.type = action.params.type;
      if (action.params.promotion) company.promotion = action.params.promotion;

      // console.log("1. ++++");
      // console.log(company);
      // console.log(value);

      return { ...company };

    default:
      return company;
  }
};

export default applyCompanyReducer;
