/**
 * Created by Jeongbin on 2017-06-27.
 */
/**
 * Created by Jeongbin on 2017-06-27.
 */
import {APPLY_BILL} from '../actions/ActionTypes';

const initialState = {
  bill: {
    isShow: false,
    form: {
      company: {
        value: ''
      },
      owner: {
        value: ''
      },
      businessNum: {
        value: ''
      },
      field: {
        value: ''
      },
      type: {
        value: ''
      },
      address: {
        value: ''
      },
      manager: {
        value: ''
      },
      email: {
        value: ''
      },
      tel: {
        value: ''
      }
    }
  }
};

const applyBillReducer = (bill = initialState.bill, action) => {
  let {name, value} = action.params || {name: '', value: ''};

  switch (action.bill) {
  case APPLY_BILL.CHANGE:
    bill.form[name].value = value;
    return {...bill};

  case APPLY_BILL.TOGGLE:
    bill.isShow = !bill.isShow;
    return {...bill};

  default:
    return bill;
  }
};

export default applyBillReducer;