/**
 * Created by Jeongbin on 2017-06-16.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { withCookies } from "react-cookie";

/* UI component */

import Breadcrumb from "./Breadcrumb";
import Company from "./Company";
import Email from "./Email";

import Payment from "./Payment";
import Customer from "./Customer";

const Product = (props) => {
  const { location } = props;

  //     // breadcrumb 상태값
  //     //최초 화면은 이메일
  //     //다음 화면은 결제 페이지


  let step = location.pathname === "/apply" ? "email" : "payment";

  
  // console.log("## MAIN ##")
  // console.log(step)
  // console.log(location.pathname)

  return (
    <Wrapper>
      <Breadcrumb step={step} {...props} />
      <Grid>

        <Row>
          {location.pathname === "/payment" ? (
            <div>
              <Payment {...props} />
              <Customer {...props} />{" "}
            </div>
          ) : (
            <Email {...props} />
          )}
        </Row>
        <Row>
          <Company {...props} />
        </Row>


      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 970px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 485px 485px;
  width: 970px;
  /*height:800px;*/
  justify-content: center;
`;
const Row = styled.div`
  border: 0px solid gray;
  width: 485px;
`;

Product.propTypes = {
  user: PropTypes.object,
  how: PropTypes.object,
  types: PropTypes.object,
  payment: PropTypes.object,
  company: PropTypes.object,

  usage: PropTypes.object,
  usageType: PropTypes.object,
  license: PropTypes.object,
  button: PropTypes.object,

  master: PropTypes.object,

  query: PropTypes.object,
  history: PropTypes.object,
  complete: PropTypes.object,

  cookies: PropTypes.object,

  onUser: PropTypes.func,
  onStudent: PropTypes.func,
  onHow: PropTypes.func,
  onPayment: PropTypes.func,
  onReceipt: PropTypes.func,
  onUsage: PropTypes.func,
  onLicense: PropTypes.func,
  onButton: PropTypes.func,
  onComplete: PropTypes.func,
};

export default withCookies(Product);
