/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_USER } from "../actions/ActionTypes";

const initialState = {
  user: {
    // direct: false,
    // directValue: '',
    selected: 1,
    options: [
      {
        label: "1명",
        value: 1,
      },
      {
        label: "2명",
        value: 2,
      },
      {
        label: "3명",
        value: 3,
      },
      {
        label: "4명",
        value: 4,
      },
      {
        label: "5명",
        value: 5,
      },
      {
        label: "6명",
        value: 6,
      },
      {
        label: "7명",
        value: 7,
      },
      {
        label: "8명",
        value: 8,
      },
      {
        label: "9명",
        value: 9,
      },
      {
        label: "10명",
        value: 10,
      },
    ],
  },
};

const applyUserReducer = (user = initialState.user, action) => {
  let { value } = action.params || { id: "", value: "" };

  switch (action.user) {
    case APPLY_USER.CHANGE:
      Object.keys(user.options).forEach((key) => {
        if (user.options[key].value === value) {
          user.selected = parseInt(value);
        }
      });

      return { ...user };

    case APPLY_USER.DIRECT_CHANGE:
      user.selected = value;
      user.directValue = value;

      return { ...user };

    case APPLY_USER.DIRECT_BLUR:
      let resultValue = parseInt(value, 10);
      value = value && value > 0 ? parseInt(value, 10) : 1;

      if (value < 8) {
        Object.keys(user.options).forEach((key) => {
          user.options[key].selected = value === user.options[key].value;
        });
        user.options["userDirect"].label = "+";
      } else {
        user.options["userDirect"].label = resultValue;
      }

      user.direct = false;
      user.selected = value;
      user.directValue = resultValue > 0 ? resultValue : "";

      return { ...user };

    default:
      return user;
  }
};

export default applyUserReducer;
