/**
 * Created by Jeongbin on 2017-05-18.
 */
import React from "react";
import { connect } from "react-redux";
import * as action from "../../actions";

import ScrollToTop from "../../components/ScrollToTop";
import Apply from "../../components/Main/Apply";
import HeaderSubIndex from "../../components/Main/HeaderSubIndex";

import PropTypes from "prop-types";

const App = (props) => {
  // console.log("@ container > apply");
  // console.log(props);

  return (
    <>
      <ScrollToTop />
      <HeaderSubIndex {...props} />
      <Apply {...props} />
    </>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ...state,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChoice: (choice, params) => {
      dispatch(action.homeChoiceAction(choice, params));
    },
    // onCustomer: (type, params) => {
    //     dispatch(action.applyCustomerAction(type, params));
    // },
    onCustomer: (customer, params) => {
      dispatch(action.applyCustomerAction(customer, params));
    },

    onComplete: (type, params) => {
      dispatch(action.applyCompleteAction(type, params));
    },
    onUser: (user, params) => {
      dispatch(action.applyUserAction(user, params));
    },
    onStudent: (student, params) => {
      dispatch(action.applyStudentAction(student, params));
    },
    onHow: (how, params) => {
      dispatch(action.applyHowAction(how, params));
    },
    onPayment: (payment, params) => {
      dispatch(action.applyPaymentAction(payment, params));
    },
    onCompany: (company, params) => {
      dispatch(action.applyCompanyAction(company, params));
    },
    onReceipt: (receipt, params) => {
      dispatch(action.applyReceiptAction(receipt, params));
    },
    onUsage: (usage, params) => {
      dispatch(action.applyUsageAction(usage, params));
    },
    onLicense: (license, params) => {
      dispatch(action.applyLicenseAction(license, params));
    },
    onButton: (button, params) => {
      dispatch(action.applyButtonAction(button, params));
    },
    onMaster: (master, params) => {
      dispatch(action.applyMasterAction(master, params));
    },
    onPromotion: (promotion, params) => {
      dispatch(action.applyPromotionAction(promotion, params));
    },
    onCardReceipt: (cardReceipt, params) => {
      dispatch(action.applyCardReceiptAction(cardReceipt, params));
    },

    onBill: (bill, params) => {
      dispatch(action.applyBillAction(bill, params));
    },
    asyncButton: (type, params) => {
      dispatch(action.applyButtonRequest(type, params));
    },
    // asyncPromotion: (type, params) => {
    //   dispatch(action.applyPromotionRequest(type, params));
    // },
    // onLogin: (login, params) => {
    //   dispatch(action.applyLoginAction(login, params));
    // },
  };
}

App.propTypes = {
  how: PropTypes.object,
  types: PropTypes.object,
  user: PropTypes.object,
  complete: PropTypes.object,
  payment: PropTypes.object,
  edu: PropTypes.object,
  company: PropTypes.object,
  receipts: PropTypes.object,
  usage: PropTypes.object,
  license: PropTypes.object,
  button: PropTypes.object,
  master: PropTypes.object,
  promotion: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  login: PropTypes.object,

  onChoice: PropTypes.func,
  onCustomer: PropTypes.func,
  onComplete: PropTypes.func,
  onUser: PropTypes.func,
  onStudent: PropTypes.func,
  onHow: PropTypes.func,
  onPayment: PropTypes.func,
  onCompany: PropTypes.func,
  onReceipt: PropTypes.func,
  onUsage: PropTypes.func,
  onLicense: PropTypes.func,
  onButton: PropTypes.func,
  onMaster: PropTypes.func,
  onPromotion: PropTypes.func,
  onLogin: PropTypes.func,
  asyncButton: PropTypes.func,
  asyncPromotion: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
