/**
 * Created by Jeongbin on 2017-05-16.
 */
import React from "react";
import { withCookies } from "react-cookie";

import "./Apply.css";

import {
  useParams,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";

import Product from "./Product";

const ApplyView = (props) => {
  return <Product {...props} />;
};


const Apply = (props) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  props = { ...props, location, params, navigate };


  //페이지 리로드시 홈으로
  if (props.company.selected === "" && location.pathname === "/payment") {
    return <Navigate to="/" replace={true} />;
  }

  return <div className="apply">{<ApplyView {...props} />}</div>;

};



export default withCookies(Apply);
