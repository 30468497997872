/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_BUTTON } from "../actions/ActionTypes";

const initialState = {
  button: {
    text: "주문하기",
    isReceipts: false,
    isClicked: false,
  },
};

const applyButtonReducer = (button = initialState.button, action) => {
  switch (action.button) {
    case APPLY_BUTTON.SET_BUTTON:
      // let { step, query } = action.params;

      button.text = "결제하기";

      // console.log("#button , reducer");
      // console.log(step, button);

      return { ...button };

    case APPLY_BUTTON.CLICK:
      button.isClicked = true;

      return { ...button };

    default:
      return button;
  }
};

export default applyButtonReducer;
