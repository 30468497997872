/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_COMPLETE } from "../actions/ActionTypes";

const initialState = {
  complete: {
    type: "",
    how: "",
    code: "",
    message: "",
    price: 0,
  },
};

const applyCompleteReducer = (complete = initialState.complete, action) => {
  // console.log("=============== Complete Reducer ==================");
  // console.log(action);
  // console.log("action.parameter");
  // console.log(action.parameter);

  switch (action.complete) {
    case APPLY_COMPLETE.TRIAL:
      complete = {
        type: "trial",
        message:
          action.params.success === true
            ? "Trial 신청이 완료 되었습니다."
            : action.params.message,
        success: action.params.success,
      };
      return { ...complete };

    case APPLY_COMPLETE.CARD:
      complete = {
        type: "card",
        how: action.params.how.selected,
        code: action.params.merchant_uid,
        message:
          action.params.success === true
            ? "카드결제(구매)가 정상적으로 완료되었습니다."
            : action.params.message,
        price: action.params.amount,
      };

      return { ...complete };

    case APPLY_COMPLETE.PROMOTION:
      complete = {
        type: "promotion",
        how: action.params.how.selected,
        code: action.params.merchant_uid,
        message: action.params.message,
        success: action.params.message_no === 0 ? true : false,
        price: action.params.amount,
      };
      return { ...complete };

    case APPLY_COMPLETE.VBANK:
      let {
        how,
        amount,
        merchant_uid,
        success,
        vbank_date,
        vbank_holder,
        vbank_name,
        vbank_num,
      } = action.params;

      complete = {
        type: "vbank",
        how: how.selected,
        code: merchant_uid,
        price: amount,
        success,
        message:
          success === true
            ? "구매 신청이 정상적으로 접수 되었습니다."
            : " 결제가 취소 되었습니다. ",
        vbank_date,
        vbank_holder,
        vbank_name,
        vbank_num,
      };

      return { ...complete };

    default:
      return complete;
  }
};

export default applyCompleteReducer;
