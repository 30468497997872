/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_RECEIPT } from "../actions/ActionTypes";

const initialState = {
  receipts: {
    selected: "no",
    options: {
      cashBill: {
        label: "현금영수증 신청",
        value: "cashBill",
        checked: false,
      },

      taxBill: {
        label: "세금계산서 신청",
        value: "taxBill",
        checked: false,
      },
      no: {
        label: "미신청",
        value: "no",
        checked: true,
      },
    },
  },
};

const applyReceiptReducer = (receipts = initialState.receipts, action) => {
  let { id, value } = action.params || { id: "", value: "" };

  switch (action.receipt) {
    case APPLY_RECEIPT.CHANGE:
      Object.keys(receipts.options).forEach((key) => {
        receipts.options[key].checked = false;

        if (key === id) {
          receipts.options[key].checked = true;
          receipts.selected = value;
        }
      });

      return { ...receipts };

    default:
      return receipts;
  }
};

export default applyReceiptReducer;
