/**
 * Created by Jeongbin on 2017-06-27.
 */
import { APPLY_CARD_RECEIPT } from "../actions/ActionTypes";

const initialState = {
  cardReceipts: {
    selected: "cashBill",
    options: {
      cashBill: {
        label: "현금영수증",
        value: "cashBill",
        checked: true,
      },
      taxBill: {
        label: "세금계산서",
        value: "taxBill",
        checked: false,
      },
      // no: {
      //   label: "미발행",
      //   value: "no",
      //   checked: false,
      // },
    },
  },
};

const applyCardReceiptReducer = (
  cardReceipts = initialState.cardReceipts,
  action
) => {
  let { id, value } = action.params || { id: "", value: "" };

  // console.log("------------- APPLY_CARD_RECEIPT -----");
  //console.log(id, value);
  // console.log(action.cardReceipt);
  // console.log(initialState);

  // console.log(action);

  switch (action.cardReceipt) {
    case APPLY_CARD_RECEIPT.CHANGE:
      Object.keys(initialState.cardReceipts.options).forEach((key) => {
        initialState.cardReceipts.options[key].checked = false;

        if (key === id) {
          initialState.cardReceipts.options[key].checked = true;
          initialState.cardReceipts.selected = value;
        }
      });

      return { ...initialState.cardReceipts };

    default:
      return initialState.cardReceipts;
  }
};

export default applyCardReceiptReducer;
