/**
 * Created by Jeongbin on 2017-05-17.
 */
import React from "react";
import { connect } from "react-redux";

import Home from "../../components/Main/Home";
import Login from "../../components/Main/Login";
import Install from "../../components/Main/Install";
import Download from "../../components/Main/Download";

import HeaderIndex from "../../components/Main/HeaderIndex";
import HeaderSubIndex from "../../components/Main/HeaderSubIndex";

import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import {
  homeAction,
  homeChoiceAction,
  applyCustomerAction,
} from "../../actions";
//import cookie from "react-cookies";

const App = (props) => {
  // 임시로 쿠키세팅

  const location = useLocation();
  props = { ...props, location };
  // console.log("props");
  // console.log(props);
  return (
    <div>
      {location.pathname === "/" ? <HeaderIndex /> : <HeaderSubIndex />}

      <div>
        {location.pathname === "/" ? (
          <Home {...props} />
        ) : location.pathname === "/install" ? (
          <Install {...props} />
        ) : location.pathname === "/download" ? (
          <Download {...props} />
        ) : location.pathname === "/login" ? (
          <Login {...props} />
        ) : (
          <Home {...props} />
        )}
      </div>
    </div>
  );
};

// 리덕스 스토어의 상탤르 조회해서 어떤 것을 props로 넣어 줄지 정의합니다.
function mapStateToProps(state, ownProps) {
  return {
    ...state,
    ...ownProps,
  };
}

// 액션을 디스패치하는 함수를 만들어서 props로 넣어 줍니다.
function mapDispatchToProps(dispatch) {
  return {
    onHome: (home) => {
      dispatch(homeAction(home));
    },
    onChoice: (choice, params) => {
      dispatch(homeChoiceAction(choice, params));

      //var menuHeight = document.querySelector(".menu").offsetHeight;
      // var location = document.querySelector(".product-list").offsetTop;

      window.scrollTo({ top: 550, behavior: "smooth" });
    },
    onCustomer: (customer, params) => {
      dispatch(applyCustomerAction(customer, params));
    },
  };
}

App.propTypes = {
  types: PropTypes.object,
  location: PropTypes.object,
  onHome: PropTypes.func,
  onChoice: PropTypes.func,
  onCustomer: PropTypes.func,
  history: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
