/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_USAGE } from "../actions/ActionTypes";

const initialState = {
  usage: {
    method: {
      selected: "cash",
      options: {
        cash: {
          label: "소득공제",
          value: "cash",
          checked: true,
        },
        tax: {
          label: "지출증빙",
          value: "tax",
          checked: false,
        },
      },
    },
    type: {
      selected: "telno",
      code: "",
      clear: false,
      clearOver: false,
      input: null,
      placeholder: "숫자만 입력해 주세요",
      options: {
        cash: [
          {
            label: "휴대폰번호",
            value: "telno",
          },
          {
            label: "현금영수증카드",
            value: "receiptcard",
          },
        ],
        tax: [
          {
            label: "현금영수증카드",
            value: "receiptcard",
          },
          {
            label: "사업자번호",
            value: "biznum",
          },
        ],
      },
    },
  },
};

const applyUsageReducer = (usage = initialState.usage, action) => {
  let { id, value } = action.params || { id: "", value: "" };

  switch (action.usage) {
    case APPLY_USAGE.CHANGE:
      Object.keys(usage.method.options).forEach((key) => {
        // console.log("**");
        // console.log(key, id, value);
        usage.method.options[key].checked = false;
        if (key === id) {
          usage.method.options[key].checked = true;
          usage.method.selected = value;
          // usage.method.selected_option = { label: "title", value: value };
        }
      });

      usage.type.code = "";
      //      usage.type.selected_option = usage.type.options[value][0];
      // usage.type.selected =
      //   usage.method.selected === "cash" ? "receiptcard" : "no";

      // console.log("***");
      // console.log(usage.type.options[value][0]);
      // console.log(usage);

      return { ...usage };

    case APPLY_USAGE.TYPE_CHANGE:
      usage.type.selected = value;
      usage.type.code = "";
      return { ...usage };

    case APPLY_USAGE.SET_CODE:
      let { type, target } = action.params || { type: "", target: "" };
      let regex = {
        telno: /(01[0|1|6|7|9])(\d{3,4})(\d{4})/,
        biznum: /(\d{3})(\d{2})(\d{5})/,
        receiptcard: /(\d{4})(\d{4})(\d{4})(\d{4})/,
      };

      usage.type.code = usage.type.code.replace(/-/g, "");
      let result = usage.type.code.match(regex[usage.type.selected]);
      let nodeName = target.nodeName.toLowerCase();

      if (nodeName === "input") {
        if (type === "click") {
          if (result) {
            usage.type.clear = true;
          }
        } else if (type === "blur") {
          if (!usage.type.clearOver) {
            if (result) {
              result = Array.from(result);
              result.shift();
              usage.type.code = result.join("-");
            } else {
              usage.type.code = "";
            }
          }
        }
      } else {
        if (type === "mouseover") {
          usage.type.clearOver = true;
          if (result) {
            usage.type.clear = true;
          }
        } else if (type === "mouseout") {
          usage.type.clearOver = false;
          target.previousSibling.focus();
        } else if (type === "click") {
          usage.type.code = "";
          usage.type.clear = false;
          usage.type.clearOver = false;
        }
      }

      return { ...usage };

    case APPLY_USAGE.CODE_CHANGE:
      usage.type.code = value;
      usage.type.clear = !!value;

      return { ...usage };

    case APPLY_USAGE.PLACE_HOLDER:
      usage.type.placeholder = "값을 입력해 주세요";

      return { ...usage };

    default:
      return usage;
  }
};

export default applyUsageReducer;
