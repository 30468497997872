/**
 * Created by Jeongbin on 2017-05-25.
 */
import React from "react";
import { Link, Navigate } from "react-router-dom";
import {NumericFormat} from "react-number-format";
import "./Complete.css";

const Complete = (props) => {
  const { complete } = props;

  //console.clear();
  //  console.log("complete");
  //  console.log(complete);

  return (
    <div className="complete">
      {!complete.type ? (
        <Navigate to="/" />
      ) : (
        <div className="info">
          <h1>{complete.message}</h1>
          {complete.how === "vbank" && complete.success === true ? (
            <table className="account">
              <colgroup>
                <col width={220} />
              </colgroup>
              <tbody>
                <tr>
                  <td className="label">입금하실 금액</td>
                  <td>
                    <span className="price">
                      <NumericFormat
                        displayType={"text"}
                        thousandSeparator={true}
                        value={complete.price}
                      />
                      원
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="label">입금은행</td>
                  <td>{complete.vbank_name}</td>
                </tr>
                <tr>
                  <td className="label">입금계좌</td>
                  <td>
                    <span>{complete.vbank_num}</span>
                    <span className="holder">
                      예금주 : {complete.vbank_holder}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
          {
            // complete.type !== "trial" ? (
            //   <table className="period">
            //     <colgroup>
            //       <col width={220} />
            //     </colgroup>
            //     <tbody>
            //       <tr>
            //         <td rowSpan={2} className="label">
            //           주문번호
            //           <br />
            //           <span className="order-num">{complete.merchant_uid}</span>
            //         </td>
            //         <td className="price-info">
            //           <div>
            //             <span className="sub-label">총 결제 금액</span>
            //             <span className="price">
            //               <NumberFormat
            //                 displayType={"text"}
            //                 thousandSeparator={true}
            //                 value={complete.price}
            //               />
            //               원
            //             </span>
            //           </div>
            //         </td>
            //       </tr>
            //       <tr>
            //         <td>
            //           <div className="how-info">
            //             <span>
            //               {complete.how === "vbank"
            //                 ? "가상계좌"
            //                 : complete.how === "card"
            //                 ? "신용카드"
            //                 : "프로모션 코드"}
            //             </span>
            //             <span className="sub-price">
            //               <NumberFormat
            //                 displayType={"text"}
            //                 thousandSeparator={true}
            //                 value={complete.price}
            //               />
            //               원
            //             </span>
            //           </div>
            //         </td>
            //       </tr>
            //     </tbody>
            //   </table>
            // ) : complete.type === "trial" && complete.success === true ? (
            //   <p>발송된 이메일을 확인 해주세요.</p>
            // ) : (
            //   <p>라이선스 기간을 확인 해주세요.</p>
            // )
          }
          {complete.success}
          {complete.how === "promotion" && complete.success === true ? (
            <div className="notes">
              <p className="note">
                - 본 상품은 비상업적 용도로만 사용이 가능합니다. <br />- 1년
                이용 상품으로 학생신분을 유지할 경우 매년 갱신이 필요합니다.
              </p>
            </div>
          ) : null}

          {complete.how === "vbank" && complete.success === true ? (
            <div className="notes">
              <p className="note">
                ※ 주문 완료 후 {complete.vbank_date} 이내 미입금시 주문이
                자동으로 취소 됩니다 ※
              </p>
            </div>
          ) : null}

          <div className="btn-group">
            {complete.type !== "master" &&
              (complete.how === "card" || complete.how === "promotion") ? (
              <Link to="/download">
                <span className="history">폰트매니저 설치</span>
              </Link>
            ) : null}

            <a href="/mypage.service.list.php">
              <span className="homeBtn">서비스 신청 정보</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Complete;
