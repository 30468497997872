import React, { Component } from "react";
import { Link } from "react-router-dom";
//import { FormattedNumber } from "react-intl";

//import { NumberFormatBase as NumberFormat } from 'react-number-format';
import numeral from 'numeral';

import styled from "styled-components";

const PriceBox = (props) => {
    const { home, choice, color } = props;

    const selected = home.selected;


    console.log(choice)

    const Price = (props) => {
        return (
            <div>
                {props.count !== "0" ? (
                    <>
                        <div>

                            {
                                props.monthPrice > 0 ? (
                                    <div className="priceRow">
                                        <span className="Year">월</span>
                                        <span className="Price">
                                            ₩
                                            {numeral(props.monthPrice).format('0,0')}

                                        </span>
                                    </div>)
                                    :
                                    (<div className="priceRow"></div>)
                            }


                            {


                                <div className="priceRow">
                                    <span className="Year"> 년</span>


                                    {props.title !== 'SOLO 학생용' ?

                                        (
                                            <span className="Price ">
                                                ₩
                                                {numeral(props.price).format('0,0')}
                                            </span>
                                        ) :

                                        (
                                            <span className="Price strikethrough">
                                                ₩
                                                {numeral(props.price).format('0,0')}
                                            </span>
                                        )
                                    }

                                </div>

                            }

                        </div>
                        {props.title !== 'SOLO 학생용' ? (<span>{props.count} 인 사용(부가세 포함)</span>) : (<span></span>)}
                    </>
                ) : (
                    <div>
                        <span className="callus">
                            02-598-0050 <br />
                            (별도문의)
                        </span>
                    </div>
                )
                }
            </div >
        );
    };

    const BuyButton = (props) => {
        let path = "/apply" + "/" + props.product;

        return props.count !== "0" ? (
            <Link
                to={{
                    pathname: path,
                    query: { type: selected, count: props.count },
                }}
            >
                <Btn color={color}>구매하기</Btn>
            </Link>
        ) : (
            <span>상담전용상품</span>
        );
    };

    const ChoiceList = Object.keys(choice).map((key, index) => (
        <Box key={index}>
            <div className="title">{choice[key].title}</div>
            <div className="price">
                <Price {...choice[key]} />
            </div>
            <div className="desc">{choice[key].desc}</div>
            { 
              ['Smart one','Smart 25','Smart 50','SOLO','CAD','WEBTOON'].includes(choice[key].title) ? 
              (
                <div className="etc">{choice[key].count}인 사용(부가세포함)</div>
              ) : 
              ['SOLO 학생용'].includes(choice[key].title) ?
            
              (<div> <br/>프로모션상품 <br /> 년간 무상 라이선스 제공</div>) : ""
            
          
          }
            <div className="buyBtn">
                <BuyButton product={key} />
            </div>
        </Box>
    ));

    return <Boxes>{ChoiceList}</Boxes>;
};

const Boxes = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 90px;
`;
const Box = styled.div`
  background: #fff;
  color: #666;
  width: 310px;
  height: 337px;
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #dfddde;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-right: 35px;
  &:hover {
    background: #ececec;
  }

  .title {
    font-family: HY고딕M, sans-serif;
    height: 60px;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .price {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    & {
      font-family: HY고딕B, sans-serif;
      font-size: 20px;
    }
    & span {
      font-size: large;
    }

    & .priceRow {
      display: flex;
      width: 140px;

      & .Year {
        font-family: HY고딕M, sans-serif;
        font-size: large;
        width: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-items: center;
      }

      & .Price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: HY고딕M, sans-serif;
        font-size: large;
        width: 140px;
        & span {
          font-size: large;
        }
      }

     & .strikethrough {
        text-decoration: line-through;
      }

    }

    & span {
      font-size: 12px;
    }
    & .callus {
      font-size: 18px;
    }
  }

  .desc {
    height: 90px;
    font-family: HY고딕M, sans-serif;
    font-size: 16px;
    /*background-color:yellow;*/
    display: flex;
    justify-content: center;
    align-items: flex-end;
    white-space: pre-wrap;
  }

  .etc {
    font-family: HY고딕M, sans-serif;
    font-size: 10px;
    justify-content: center;
    color: #666;
  }

  .buyBtn {
    height: 114px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-family: HY고딕B, sans-serif;
      font-size: 21px;
    }
  }
`;

const Btn = styled.button`
  font-family: HY고딕M, sans-serif;
  width: 120px;
  border: none;

  display: inline-flex;
  outline: none;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;

  background-color: ${(props) => props.color};

  /*
    &:hover {
        background: #339af0;
    }
    &:active {
        background: #1c7ed6;
    }
    */

  font-size: 15px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  padding: 0.25em 1em;
  border-radius: 18px;
  justify-content: center;
`;

export default PriceBox;
