/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_HOW } from "../actions/ActionTypes";

const initialState = {
  how: {
    selected: "card",
    options: {
      card: {
        label: "신용/체크카드",
        value: "card",
        checked: true,
      },
      vbank: {
        label: "무통장 입금",
        value: "vbank",
        checked: false,
      },
      // promotion: {
      //   label: "프로모션",
      //   value: "promotion",
      //   checked: false,
      // },
    },
    comment: "",
  },
};

const applyHowReducer = (how = initialState.how, action) => {
  let { id, value } = action.params || { id: "", value: "" };
  //console.log(action.how, id, value);

  switch (action.how) {
    case APPLY_HOW.QNA_CLICK:
      how.selected = how.selected !== "qna" ? "qna" : "card";

      Object.keys(() => how.options).forEach((key) => {
        how.options[key].checked = false;
        if (key === how.selected) {
          how.options[key].checked = true;
        }
      });

      return { ...how };

    case APPLY_HOW.METHOD:
      how.selected = value ? value : "";
      return { ...how };

    case APPLY_HOW.COMMENT_CHANGE:
      how.comment = value;

      return { ...how };

    case APPLY_HOW.CHANGE:
      Object.keys(how.options).forEach((key) => {
        how.options[key].checked = false;
        if (key === id) {
          how.options[key].checked = true;
          how.selected = value;
        }
      });

      return { ...how };

    default:
      return how;
  }
};

export default applyHowReducer;
