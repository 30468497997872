/**
 * Created by Jeongbin on 2017-05-17.
 */

import { combineReducers } from "redux";
import home from "./Home";
import user from "./User";
import types from "./Types";
import complete from "./Complete";
import how from "./How";
import payment from "./Payment";
import customer from "./Customer";
import receipts from "./Receipt";
import usage from "./Usage";
import license from "./License";
import button from "./Button";
import master from "./Master";
import promotion from "./Promotion";
import cardReceipts from "./CardReceipt";
import bill from "./Bill";
import company from "./Company";

const appReducers = combineReducers({
  home,
  user,
  types,
  complete,
  how,
  payment,
  customer,
  receipts,
  usage,
  license,
  button,
  master,
  promotion,
  cardReceipts,
  bill,
  company,
});

const rootReducer = (state, action) => {
  // console.log("action : "+action.home);

  if (action.home === "RESET") {
    state = {
      ...state,
      user: {
        selected: "1",
        options: [
          {
            label: "1명",
            value: 1,
          },
          {
            label: "2명",
            value: 2,
          },
          {
            label: "3명",
            value: 3,
          },
          {
            label: "4명",
            value: 4,
          },
          {
            label: "5명",
            value: 5,
          },
        ],
      },
      complete: {
        type: "",
        how: "",
        code: "",
        message: "",
        price: 0,
      },
      how: {
        selected: "card",
        options: {
          card: {
            label: "신용/체크카드",
            value: "card",
            checked: true,
          },
          vbank: {
            label: "무통장 입금 ",
            value: "vbank",
            checked: false,
          },
          // promotion: {
          //   label: "프로모션 코드",
          //   value: "promotion",
          //   checked: false,
          // },
        },
        comment: "",
      },
      customer: {
        selected: "company",
        id: "company",
        form: {
          account: {
            value: "",
          },
          account_type: {
            value: "",
          },
          company: {
            value: "",
          },
          owner: {
            value: "",
          },
          businessNum: {
            value: "",
          },
          field: {
            value: "",
          },
          field2: {
            value: "",
          },
          address: {
            value: "",
          },
          manager: {
            value: "",
          },
          email: {
            value: "",
          },
          tel: {
            value: "",
          },
        },
      },
      payment: {
        selected: 1,
        id: "year1",
        options: {
          year1: {
            value: 1,
            period: "1년이용",
            checked: true,
            hidden: "",
          },
          year2: {
            value: 2,
            period: "2년이용",
            checked: false,
            hidden: "",
          },
        },
      },
      receipts: {
        selected: "cashBill",
        options: {
          cashBill: {
            label: "현금영수증",
            value: "cashBill",
            checked: true,
          },

          taxBill: {
            label: "세금계산서",
            value: "taxBill",
            checked: false,
          },
          //   no: {
          //     label: "미발행",
          //     value: "no",
          //     checked: true,
          //   },
        },
      },
      usage: {
        method: {
          selected: "cash",
          options: {
            cash: {
              label: "소득공제",
              value: "cash",
              checked: true,
            },
            tax: {
              label: "지출증빙",
              value: "tax",
              checked: false,
            },
          },
        },
        type: {
          selected: "no",
          selected_option: {},
          code: "",
          clear: false,
          clearOver: false,
          input: null,
          placeholder: "숫자만 입력해 주세요",
          options: {
            cash: [
              // {
              //   label: "주민등록번호",
              //   value: "juminnum",
              // },
              // {
              //   label: "카드번호",
              //   value: "cardnum",
              // },
              {
                label: "휴대폰번호",
                value: "phonenum",
              },
            ],
            tax: [
              {
                label: "사업자번호",
                value: "biznum",
              },
            ],
          },
        },
      },
      license: {
        selected: [],
        isOpen: false,
        options: {
          agree: {
            value: "yes",
            checked: false,
          },
        },
      },
      button: {
        text: "",
        isReceipts: false,
        isClicked: false,
      },
      master: {
        errorMessages: [],
        validation: {
          message: {
            required: {
              validate: false,
              message: "메시지를 입력해 주세요.",
            },
          },
          name: {
            required: {
              validate: false,
              message: "이름을 입력해 주세요.",
            },
          },
          email: {
            required: {
              validate: false,
              message: "이메일을 입력해 주세요.",
            },
            regex: {
              value: /^([\w\-_]+)?\w+@[\w-_]+(\w+){1,}$/gim,
              validate: false,
              message: "이메일을 정확히 입력해주세요.",
            },
          },
        },
        message: {
          label: "메시지",
          value: "",
        },
        name: {
          label: "이름",
          value: "",
        },
        email: {
          label: "이메일 주소",
          value: "",
        },
        company: {
          label: "회사 / 기관",
          value: "",
        },
        tel: {
          label: "전화",
          value: "",
        },
      },
      promotion: {
        isChecked: false,
        isSuccess: false,
        value: "",
        result: "",
        message: "",
      },
      // taxReceipt: {
      //   selected: "no",
      // },
      cardReceipts: {
        selected: "no",
      },
      bill: {
        isShow: false,
        form: {
          company: {
            value: "",
          },
          owner: {
            value: "",
          },
          businessNum: {
            value: "",
          },
          field: {
            value: "",
          },
          field2: {
            value: "",
          },
          address: {
            value: "",
          },
          manager: {
            value: "",
          },
          email: {
            value: "",
          },
          tel: {
            value: "",
          },
        },
      },
      company: {
        selected: "",
        type: "",
      },
    };
    //state = {...initialState};
  }

  return appReducers(state, action);
};

export default rootReducer;
