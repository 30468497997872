/**
 * Created by Jeongbin on 2017-06-25.
 */

export const API_URL = process.env.REACT_APP_API_ROOT;


export const HOME = {
  RESET: "RESET",
};

export const HOME_CHOICE = {
  CHANGE: "CHANGE",
};

/*
 export const APPLY = {
 RESET: 'RESET'
 };
 */

export const APPLY_USER = {
  CHANGE: "CHANGE",
  DIRECT_CHANGE: "DIRECT_CHANGE",
  DIRECT_BLUR: "DIRECT_BLUR",
};

export const APPLY_COMPLETE = {
  TRIAL: "TRIAL",
  VBANK: "VBANK",
  CARD: "CARD",
  PROMOTION: "PROMOTION",
};

export const APPLY_HOW = {
  QNA_CLICK: "QNA_CLICK",
  COMMENT_CHANGE: "COMMENT_CHANGE",
  CHANGE: "CHANGE",
  METHOD: "METHOD",
};

export const APPLY_STEP = {
  CHANGE: "CHANGE",
};

export const APPLY_PAYMENT = {
  CHANGE: "CHANGE",
};

export const APPLY_COMPANY = {
  CHANGE: "CHANGE",
};

export const APPLY_STUDENT = {
  CHANGE: "CHANGE",
  CHECK_VALIDATE: "CHECK_VALIDATE",
};

export const APPLY_CARD_RECEIPT = {
  CHANGE: "CHANGE",
};

export const APPLY_RECEIPT = {
  CHANGE: "CHANGE",
};

export const APPLY_USAGE = {
  CHANGE: "CHANGE",
  TYPE_CHANGE: "TYPE_CHANGE",
  SET_CODE: "SET_CODE",
  CODE_CHANGE: "CODE_CHANGE",
  PLACE_HOLDER: "PLACE_HOLDER",
};

export const APPLY_LICENSE = {
  CHANGE: "CHANGE",
  TOGGLE: "TOGGLE",
};

export const APPLY_BUTTON = {
  CLICK: "CLICK",
  SET_BUTTON: "SET_BUTTON",
};

export const APPLY_MASTER = {
  CHANGE: "CHANGE",
  CHECK_VALIDATE: "CHECK_VALIDATE",
};

export const APPLY_PROMOTION = {
  CHANGE: "CHANGE",
  CODE_CHECK: "CODE_CHECK",
};

export const APPLY_BILL = {
  CHANGE: "CHANGE",
  TOGGLE: "TOGGLE",
};

export const APPLY_CUSTOMER = {
  CHANGE: "CHANGE",
  TOGGLE: "TOGGLE",
  PUT: "PUT",
};

export const APPLY_LOGIN = {
  CHANGE: "CHANGE",
};
