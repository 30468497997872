/**
 * Created by Jeongbin on 2017-06-22.
 */
import { APPLY_PROMOTION } from "../actions/ActionTypes";

const initialState = {
    promotion: {
        isChecked: false,
        isSuccess: false,
        value: "",
        result: "",
        message: "",
    },
};

const applyPromotionReducer = (promotion = initialState.promotion, action) => {
    let { value, data } = action.params || { value: "", data: {} };

    switch (action.promotion) {
        case APPLY_PROMOTION.CHANGE:
            promotion.value = value;

            return { ...promotion };

        case APPLY_PROMOTION.CODE_CHECK:
            promotion.isChecked = true;
            promotion.isSuccess = data.isSuccess;
            promotion.message = data.message;
            promotion.result = data.code === 200 ? "success" : "error";

            return { ...promotion };

        default:
            return promotion;
    }
};

export default applyPromotionReducer;
