/**
 * Created by Jeongbin on 2017-05-15.
 */
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../containers/Main/Home";
import Apply from "../containers/Main/Apply";
import Complete from "../containers/Main/Complete";

import { Provider } from "react-redux";
import { store } from "../store";
//import history from "../components/history";
import { withCookies } from "react-cookie";

const App = (props) => {
  return (
    <Provider store={store}>
      <Router>
        <Routes >
          <Route path="/" exact="true" element={<Home />} />
          <Route path="/download" {...props} element={<Home />} />
          <Route path="/install" {...props} element={<Home />} />
          <Route path="/apply/:option" {...props} element={<Apply />} />
          <Route path="/payment" {...props} element={<Apply />} />
          <Route path="/complete" element={<Complete />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default withCookies(App);
