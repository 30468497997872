import React, { useState } from 'react'
import axios from 'axios';
import * as type from "../../actions/ActionTypes";
import styled from "styled-components";
// import { PatternFormat } from 'react-number-format';
import { FormattedInput } from "@buttercup/react-formatted-input";

/**
 * 묵향 코드 입력 폼
 * @returns
 */
const MookHyangCDKey = (props) => {

    const { onCompany } = props;
    const [cdKey, setCdKey] = useState("");
    const [message, setMessage] = useState("")


    const fetchCDkey = async () => {
        const req = { cdkey: cdKey };

        axios
            .post(`${type.API_URL}cdkey`, req)
            .then((response) => {
                let res_data = response.data;
                return res_data;
            })
            .then((result) => {
                if (result === 'ok') {
                    onCompany("CHANGE", {
                        promotion: true,
                        price: "440000",
                        type: 'unlimited'
                    });
                    setMessage('시리얼코드가 확인 되었습니다.')
                } else {
                    setMessage('잘못된 시리얼코드 입니다.')
                }
            })
    }


    const idPattern = [
        { char: /[a-zA-Z0-9]/, repeat: 4 },
        { exactly: "-" },
        { char: /[a-zA-Z0-9]/, repeat: 4 },
        { exactly: "-" },
        { char: /[a-zA-Z0-9]/, repeat: 4 },
        { exactly: "-" },
        { char: /[a-zA-Z0-9]/, repeat: 4 },
        { exactly: "-" },
        { char: /[a-zA-Z0-9]/, repeat: 4 },
        { exactly: "-" },
        { char: /[a-zA-Z0-9]/, repeat: 4 },
        { exactly: "-" },
        { char: /[a-zA-Z0-9]/, repeat: 4 }
    ];


    // 버튼 클릭
    const checkHandler = () => {
        fetchCDkey()
    };


    return (
        <PromotionForm>
            <h2> 묵향 보유 고객 할인 (55%) </h2>
            <div className="input-form">
                <div className="col-serial">
                    <FormattedInput
                        className="formatted-input"
                        format={idPattern}
                        value={cdKey}
                        onChange={(formattedValue, raw) => { setCdKey(formattedValue) }}
                        placeholder="시리얼번호를 입력해주세요."
                    />
                </div>
                <div className="col-confirm">
                    <button className="promotion-confirm-Btn" onClick={checkHandler}>
                        확인
                    </button>
                </div>
            </div>
            <div>
                {message}
            </div>
        </PromotionForm>
    );
};

const PromotionForm = styled.div`
  display: flex;
  flex-direction: column;
  .input-form {
    display: flex;

    .col-serial {
      flex-basis: 80%;
    }
    .col-confirm {
      flex-basis: 20%;

      .promotion-confirm-Btn {
        appearance: none;
        width: 80px;
        background: #08cdd4;
        color: #fff;
        margin: 0;
        padding: 0.2rem 0.2rem;
        font-family: HY고딕M, sans-serif;
        font-size: 14px;
        text-align: center;
        -webkit-text-decoration: none;
        text-decoration: none;
        border: none;
        border-radius: 4px;
        display: inline-block;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%),
          0 2px 4px -1px rgb(0 0 0 / 6%);
        cursor: pointer;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    input {
      display: flex;
      width: 98%;
      flex-basis: 100%;
      margin: 0;
      maxlength: 80;
      border: 1px solid #dfdedf;
      border-radius: 3px;
      font-family: HY고딕M, sans-serif;
      font-size: 16px;
      outline: none;
      height: 41px;
      box-sizing: border-box;
    }
  }
`;



export default MookHyangCDKey;