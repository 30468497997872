/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_LICENSE } from '../actions/ActionTypes';

const initialState = {
  license: {
    selected: [],
    isOpen: true,
    options: {
      agree: {
        value: 'yes',
        checked: true
      }
    }
  }
};


const applyLicenseReducer = (license = initialState.license, action) => {
  let { value } = action.params || { id: '', value: '' };

  switch (action.license) {
    case APPLY_LICENSE.CHANGE:

      Object.keys(license.options).forEach((key) => {
        license.options[key].checked = !license.options[key].checked;

        if (license.options[key].checked) {
          license.selected.push(value);
        } else {
          license.selected = license.selected.filter(item => {
            return item !== value;
          });
        }
      });

      return { ...license };

    case APPLY_LICENSE.TOGGLE:

      license.isOpen = !license.isOpen;

      return { ...license };

    default:
      return license;
  }
};

export default applyLicenseReducer;
