import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

class Breadcrumb extends Component {

  render() {
    const { step } = this.props;
    // const { onCustomer } = this.props;
    // const { hide } = this.state;

    let stepStatus = step ? step : "email";

    let steps = [
      {
        id: 1,
        ename: "email",
        name: "주문 ",
      },
      {
        id: 2,
        ename: "payment",
        name: "결제 ",
      },
      {
        id: 3,
        ename: "download",
        name: "폰트매니저 설치",
      },
      {
        id: 4,
        ename: "install",
        name: "폰트 사용",
      },
    ];

    return (
      <Wrapper>
        <div className="groups">
          {steps.map((step, index) =>
            stepStatus === step.ename ? (
              <Items key={index} className="b">
                <span className="active"> {step.id} </span> {step.name}{" "}
                {step.id !== 4 ? ">" : ""}
              </Items>
            ) : (
              <Items key={index}>
                <span> {step.id} </span> {step.name} {step.id !== 4 ? ">" : ""}
              </Items>
            )
          )}

          {/* <Items><span >1</span> 이메일 &gt; </Items>
               <Items className="active" ><span className="active"> 2 </span> 결제  &gt;</Items>
               <Items><span>3</span> 폰트매니저 &gt;</Items>
               <Items><span>4</span> 설치</Items> */}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
    width: 900px;
    font-size: 18px;
    padding-bottom: 8px;
    
    border-bottom: 1px solid #dee2e6;

    .groups {
        display: inline-flex;
        align-items: center
        justify-content: space-around;
        width: 70%;
    }
`;
const Items = styled.div`
  display: flex;
  align-items: center;

  font-family: HY고딕L, sans-serif;
  font-size: 24px;

  margin-right: 8px;

  .active {
    background-color: #8d8f93;
    font-weight: 600;
  }

  span {
    width: 25px;
    height: 25px;

    font-size: 12px;
    color: #fff;
    border-radius: 1px;
    border: 1px solid #dfddde;
    background-color: #dfddde;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    margin-right: 5px;
  }
`;

Breadcrumb.propTypes = {
  query: PropTypes.object,
  customer: PropTypes.object,
};

export default Breadcrumb;
