/**
 * Created by jonghwan on 2021-03-16.
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "./Breadcrumb.js";
import { isMacOs } from "react-device-detect";

import guideImageMac from "../../assets/img/guide/guide-install-mac.png";
import guideImage from "../../assets/img/guide/guide-install.png";

class Install extends Component {
 

  render() {
    let imgGuide;

    if (isMacOs) {
      imgGuide = guideImageMac;
    } else {
      imgGuide = guideImage;
    }

    return (
      <Wrapper>
        <Container>
          <div className="breadcrumb">
            <Breadcrumb step="install" />
          </div>
          <div className="title">
            <div>
              <h3>폰트사용보기</h3>
            </div>
          </div>
          {/* <span className="memo">*사용하는 OS에 따라 화면이 다를수 있습니다.</span> */}
          <img src={imgGuide} width="1019px" alt="guide" />
          <div className="button">
            <Link to="/">
              <Button>메인 홈으로</Button>
            </Link>
          </div>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center !important;
`;
const Container = styled.div`
  display: flex;
  width: 1022px;
  flex-direction: column;
  justify-content: center;

  .breadcrumb {
    margin-top: 35px;
  }

  .title {
    display: inline-flex;
    margin-top: 50px;

    h3 {
      font-family: HY고딕L, sans-serif;
      font-size: 32px;
      width: 300px;
    }

    .icons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .guide-image {
    background: url(/img/guide/guide-install.png) no-repeat center top;
    height: 1870px;
  }

  .button {
    display: flex;
    justify-content: center;
    padding: 30px 0px;
  }

  .memo {
    font-size: 12px;
    margin-left: 10px;
    padding-bottom: 10px;
  }
`;
const Button = styled.div`
  --button-bg-color: #08cdd4;
  --button-color: #ffffff;
  --button-hover-bg-color: #025ce2;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 230px;
  background: var(--button-bg-color);
  color: var(--button-color);
  margin: 0;
  padding: 0.5rem 1rem;
  font-weight: 400;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: none;
  border-radius: 25px;
  display: inline-block;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 0.6rem;
  padding-bottom: 0.5rem;
`;

export default Install;
