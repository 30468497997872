/**
 * Created by Jeongbin on 2017-06-22.
 */
import { APPLY_MASTER } from '../actions/ActionTypes';

const initialState = {
  master: {
    errorMessages: [],
    validation: {
      message: {
        required: {
          validate: false,
          message: '메시지를 입력해 주세요.'
        }
      },
      name: {
        required: {
          validate: false,
          message: '이름을 입력해 주세요.'
        }
      },
      email: {
        required: {
          validate: false,
          message: '이메일을 입력해 주세요.'
        },
        regex: {
          value: /^([\w\-_]+)?\w+@[\w-_]+(\w+){1,}$/igm,
          validate: false,
          message: '이메일을 정확히 입력해주세요.'
        }
      }
    },
    message: {
      label: '메시지',
      value: ''
    },
    name: {
      label: '이름',
      value: ''
    },
    email: {
      label: '이메일 주소',
      value: ''
    },
    company: {
      label: '회사 / 기관',
      value: ''
    },
    tel: {
      label: '전화',
      value: ''
    }
  }
};

const applyMasterReducer = (master = initialState.master, action) => {

  let { name, value } = action.params || { name: '', value: '' };

  switch (action.master) {
    case APPLY_MASTER.CHANGE:

      Object.keys(master.validation).forEach(key => {
        if (key === name) {
          Object.keys(master.validation[key]).forEach(type => {
            if (type === 'required') {
              master.validation[key].required.validate = !!value;
            }
            if (type === 'regex') {
              master.validation[key].regex.validate = !!value.match(master.validation[key].regex.value);
            }
          });
        }
      });

      master[name].value = value;

      master.errorMessages = [];

      Object.keys(master.validation).forEach(key => {
        Object.keys(master.validation[key]).forEach(type => {
          if (!master.validation[key][type].validate) {
            master.errorMessages.push(master.validation[key][type].message);
          }
        });
      });

      return { ...master };

    case APPLY_MASTER.CHECK_VALIDATE:
      master.errorMessages = [];

      Object.keys(master.validation).forEach(key => {
        Object.keys(master.validation[key]).forEach(type => {
          if (!master.validation[key][type].validate) {
            master.errorMessages.push(master.validation[key][type].message);
          }
        });
      });

      return { ...master };

    default:
      return master;
  }
};

export default applyMasterReducer;