const initialState = {
  types: {
    trial: {
      name: "Trial",
      selected: "trial",
      options: {
        trial: {
          total: 0,
          price: 0,
          label: "폰트바다 30일 사용권 ",
          name: "트라이얼",
          type: "비상업용",
          copy: "1",
          code: "TR-NO-ALL",
          category: "일반",
          prices: [
            {
              label: "30일",
              value: 0,
              type: "day",
            },
          ],
        },
      },
      usage: ["사용범위 <br/><span> - 문서, 인쇄물, 웹디자인</span>"],
    },
    webtoon: {
      name: "WEBTOON",
      selected: "webtoon1",
      options: {
        webtoon1: {
          label: "웹툰폰트 1년 사용권",
          name: "WEBTOON",
          type: "상업용",
          code: "WT-NO-ALL",
          copy: "1",
          prices: [
            {
              label: "연",
              value: 110000,
              type: "year",
            },
            {
              label: "월",
              value: 10000,
              type: "month",
            },
          ],
          category: "일반",
        },
      },
      usage: ["웹툰 전용폰트 <br/><span> - </span>"],
    },
    cad: {
      name: "CAD",
      selected: "cad1",
      options: {
        cad1: {
          month_price: 8000,
          label: "CAD 1년 사용권 ",
          name: "CAD",
          type: "상업용",
          code: "CA-NO-ALL",
          copy: "1",
          prices: [
            {
              label: "연",
              value: 88000,
              type: "year",
            },
            {
              label: "월",
              value: 8000,
              type: "month",
            },
          ],
          category: "일반",
        },
      },
      usage: ["캐드 전용폰트 <br/><span> - </span>"],
    },
    solo: {
      name: "SOLO",
      selected: "solo1",
      options: {
        solo1: {
          label: "SOLO 1년 사용권  - 비상업용(납품불가)",
          name: "SOLO",
          type: "비상업용",
          code: "SO-NO-ALL",
          copy: "1",
          prices: [
            {
              label: "연",
              value: 99000,
              type: "year",
            },
            {
              label: "월",
              value: 9000,
              type: "month",
            },
          ],
          category: "일반",
        },
        soloEdu: {
          label: "학생용 1년 사용권  - 비상업용(납품불가)",
          name: "SOLO EDU",
          type: "초ᆞ중ᆞ고등학생용, 대학생용",
          code: "SO-EDU-ALL",
          copy: "1",
          prices: [
            {
              label: "연",
              value: 0,
              type: "year",
            },
        
          ],
          category: "학생",
        },
      },

      usage: ["사용범위 <br/><span>- </span>"],
    },
    smart: {
      name: "Smart",
      selected: "smart1",
      options: {
        smart1: {
          label: "1년 사용권  -  1인 사용",
          name: "Smart One",
          type: "1인기업",
          code: "SM-SM1-ALL",
          copy: "1",
          prices: [
            {
              label: "연",
              value: 440000,
              type: "year",
            },
            {
              label: "월",
              value: 40000,
              type: "month",
            },
          ],
          category: "smart 1",
        },
        smart25: {
          label: "1년 사용권  -  25인 사용",
          name: "Smart 25",
          type: "중소기업",
          code: "SM-SM25-ALL",
          copy: "25",

          category: "smart 25",

          prices: [
            {
              label: "연",
              value: 990000,
              type: "year",
            },
            {
              label: "월",
              value: 90000,
              type: "month",
            },
          ],
        },
        smart50: {
          label: "1년 사용권  -  50인 사용",
          name: "Smart 50",
          type: "공공기관",
          code: "SM-SM50-ALL",
          copy: "50",
          prices: [
            {
              label: "연",
              value: 1430000,
              type: "year",
            },
            {
              label: "월",
              value: 120000,
              type: "month",
            },
          ],

          category: "smart 50",
        },
      },
      usage: ["사용범위 <br/><span>- </span>"],
    },
    mookhyang: {
      name: "MOOKHYANG",
      selected: "mookhyang",
      options: {
        mookhyang: {
          label: "묵향온라인",
          name: "묵향 온라인 (MOOKHYANG ONLINE)",
          type: "상업용",
          code: "MH-NO-ALL",
          copy: "1",
          prices: [
            {
              label: "",
              value: 990000,
              type: "unlimited",
            },
          ],
          category: "일반",
        },
      },
    },
    master: {
      name: "Master",
      total: "",
    },
  },
};

const applyTypesReducer = (types = initialState.types) => {
  return types;
};

export default applyTypesReducer;
