/**
 * Created by Jeongbin on 2017-06-21.
 */
import { APPLY_CUSTOMER } from "../actions/ActionTypes";
const initialState = {
  customer: {
    selected: "company",
    form: {
      account: {
        value: "",
      },
      account_type: {
        value: "",
      },
      company: {
        value: "",
      },
      owner: {
        value: "",
      },
      businessNum: {
        value: "",
      },
      field: {
        value: "",
      },
      field2: {
        value: "",
      },
      address: {
        value: "",
      },
      manager: {
        value: "",
      },
      email: {
        value: "",
      },
      tel: {
        value: "",
      },
      attach: {
        value: "",
      },
    },
  },
};

// console.log("initialState.payment:"+  Object.keys(initialState));

const applyCustomerReducer = (customer = initialState.customer, action) => {
  let { name, value } = action.params || { name: "", value: "" };

  switch (action.customer) {
    case APPLY_CUSTOMER.TOGGLE:
      Object.keys(customer.options).forEach((key) => {
        customer.options[key].checked = false;
        if (key === value) {
          customer.options[key].checked = true;
          customer.selected = value;
          customer.id = value;
        }
      });
      return { ...customer };

    case APPLY_CUSTOMER.CHANGE:
      Object.keys(customer.form).forEach((key) => {
        if (key === name) {
          if (key === "businessNum") {
            customer.form[key].value = value
              .replace(/[^0-9]/g, "")
              .replace(/(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`);
          } else if (key === "tel") {
            customer.form[key].value = value
              .replace(/[^0-9]/g, "")
              .replace(/(\d{2}|\d{3})(\d{3}|\d{4})(\d{4})$/, `$1-$2-$3`);

            //}
          } else {
            customer.form[key].value = value;
          }
        }
      });

      return { ...customer };

    case APPLY_CUSTOMER.PUT:
      // 서버에서 받아 온 값으로 변경
      let userData = value;

      if (value) {
        Object.keys(customer.form).forEach((key) => {
          if (userData[key]) {
            customer.form[key].value = userData[key];
            // console.log(key, userData[key]);
          }
        });
      }

      //console.log(customer);

      return { ...customer };

    default:
      return customer;
  }
};

export default applyCustomerReducer;
