import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";

import { CookiesProvider } from "react-cookie";

import FontBada from "./components/index";


const rootNode = document.getElementById("root");

ReactDOM.createRoot(rootNode).render(
  <IntlProvider locale="en">
    <CookiesProvider>
      <FontBada />
    </CookiesProvider>
  </IntlProvider>

);

