/**
 * Created by Jeongbin on 2017-06-21.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

class Button extends Component {
  constructor(props) {
    super(props);

    this.buttonClick = this.buttonClick.bind(this);
  }

  componentDidMount() {
    // const {how, query, onButton} = this.props;
    // onButton('SET_BUTTON', {how, query});
  }

  //button click
  buttonClick(e) {
    const { location } = this.props;

    // console.log("!ButtonClick");
    //console.log(location.pathnamename)

    if (location.pathname === "/apply/payment") {
      // console.log("++++++++ 결제버튼 시작 +++++++++");
      // console.log(how);
      // console.log(company);
      // console.log(user);
      // console.log(receipts);
      // console.log(payment);
      // console.log("++++++++ 결제버튼 끝 +++++++++");

      // 결제 방법 선택
      // let paymentSelected = how.selected;

      //asyncButton(paymentSelected, this.props);
    }
  }

  buttonView() {
    const { button, query, how } = this.props;
    // const { onHow, onButton } = this.props;

    // console.log("#button")

    // console.log(typeof(how))
    // console.log(how)
    // console.log(step,button)

    //query = query ? query : {"type":""}

    let productType = query ? query.type : "login";

    const buttonView = (
      <BtnWrapper>
        <Buttons
          type="submit"
          className={`${productType} ${how.selected}`}
          onClick={this.buttonClick}
        >
          {button.text}
        </Buttons>

        {/* <button className="cancel" 
			onClick={handleClick}>
          돌아가기 &gt;
        </button> */}
      </BtnWrapper>
    );

    const buttonLoginView = (
      <BtnWrapper>
        <Buttons type="submit" className="login" onClick={this.buttonClick}>
          {button.text}
        </Buttons>
      </BtnWrapper>
    );

    // const message = (
    //   <div className="btn-group">
    //     <a href="/login.php">로그인 후 이용해 주세요</a>
    //     <a href="/">홈으로 가기 &gt;</a>
    //   </div>
    // );

    return query ? buttonView : buttonLoginView;
    //return buttonView;
  }

  render() {
    return this.buttonView();
  }
}

export const Buttons = styled.button`
  --button-color: #ffffff;
  --button-hover-bg-color: #025ce2;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 230px;

  background: var(--button-bg-color);
  color: var(--button-color);

  margin: 0;
  padding: 0.5rem 1rem;

  font-family: HY고딕M, sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;

  border: none;
  border-radius: 4px;

  display: inline-block;
  /*width: auto;*/

  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  cursor: pointer;

  transition: 0.5s;

  padding-top: 0.6rem;
  padding-bottom: 0.5rem;
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;

  --button-bg-color: #08cdd4;

  /*
    .trial {
        --button-bg-color: #00bbd4;
    }

    .webtoon {
        --button-bg-color: #fcc728;
    }

    .cad {
        --button-bg-color: #cde736;
    }

    .solo {
        --button-bg-color: #08cdd4;
    }

    .smart {
        --button-bg-color: #e81f61;
    }

    .fontcare {
        --button-bg-color: #7929b5;
    }

    .login {
        --button-bg-color: #666;
    }
    */
`;

Button.propTypes = {
  history: PropTypes.object,
  query: PropTypes.object,
  how: PropTypes.object,
  master: PropTypes.object,
  receipts: PropTypes.object,
  license: PropTypes.object,
  button: PropTypes.object,
  types: PropTypes.object,
  payment: PropTypes.object,
  user: PropTypes.object,
  usage: PropTypes.object,
  promotion: PropTypes.object,

  onButton: PropTypes.func,
  asyncButton: PropTypes.func,
  onComplete: PropTypes.func,
  onHow: PropTypes.func,
  onUsage: PropTypes.func,
  onMaster: PropTypes.func,
};

export default Button;
