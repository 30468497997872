/**
 * Created by Jeongbin on 2017-05-25.
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Complete from '../../components/Main/Complete';
import PropTypes from 'prop-types';
import HeaderSubIndex from '../../components/Main/HeaderSubIndex';

class App extends Component {
  render() {
    return (
      <div>
        <HeaderSubIndex />
        <Complete
          {...this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...state,
    ...ownProps
  };
}

App.propTypes = {
  complete: PropTypes.object,
  history: PropTypes.object
};

export default connect(mapStateToProps)(App);
